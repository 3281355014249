import domReady from '@roots/sage/client/dom-ready';
import $ from "jquery";

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  mobileMenuToggle();
  menuAnimation();
  
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);


function mobileMenuToggle() {
  const menuBtn = $('.menu-toggle');
  menuBtn.on('click', () => {
    $('body').toggleClass('menu-open');
  });

}

function menuAnimation() {
  $('#menu-header-menu .menu-item-has-children').on('mouseenter', function() {
    let thisSubmenuHeight = $(this).find('.sub-menu').outerHeight();

    if($(window).outerWidth() < 1024) {
      $(this).find('.sub-menu').fadeIn();
    } else {
      $('body').addClass('submenu-open');
      $('.submenu-overlay').css({
        'height': 'calc(100% + '+ thisSubmenuHeight + 'px)',
        'opacity': 1
      });
    }
  });
  $('#menu-header-menu .menu-item-has-children').on('mouseleave', function() {
    
    if($(window).outerWidth() < 1024) {
      $(this).find('.sub-menu').fadeOut();
    } else {
      $('body').removeClass('submenu-open');
      $('.submenu-overlay').css({
        'height': "100%",
        'opacity': 0
      });
    }
  });
}
